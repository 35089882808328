.Importdiv .card {
  position: relative;
  display: flex;
  padding: 20.1rem;
}
.editprofclose {
  float: right !important;
  margin-right: 25px;
  margin-top: 10px;
  color: black !important;
}
.editprofclose a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}
.closeiconview {
  float: right;
  color: black !important;
  padding-top: 15px;
}
@media only screen and (max-width: 600px) {
  .editclose {
    margin-top: -20px;
  }
}
.editprofclose {
  float: right;
  margin-right: 25px;
  margin-top: 0px;
  color: black !important;
}
.editprofclose a {
  color: black;
  text-decoration: none;
  background-color: transparent;
}
.closeiconview {
  float: right;
  color: black !important;
  padding-top: 13px;
}
