/*.................Alignment......................*/
.centerAlign {
  text-align: center;
}

.rightAlign {
  text-align: right;
}

.ctgryImg {
  width: 80px;
  height: 80px;
  margin: 5px;
}
/*....................Search Bar....................*/

.commonInput {
  width: 100% !important;
  /* margin-top: 8px; */

  /* mar
  gin-bottom: 10px !important; */
}

.commonInput input {
  padding: 0.5rem 1.5rem;
  padding-left: 30px;
  border: 1px solid #0000004d;
  outline: none;
  box-shadow: none;
  text-align: left;
  color: black;
  width: 100% !important;
  height: 35px !important;
}

.commonInput i {
  position: absolute;
}

.cmnSearchIcon {
  margin-top: 11px !important;
  margin-left: 5px !important;
  min-width: 40px;
  color: #ced4da;
}

/*....................Filter responsive....................*/

@media (max-width: 575px) {
  .commonInput {
    float: left !important;
    margin-top: 8px;
  }
}

@media (max-width: 575px) {
  .commonIn {
    float: left !important;
    margin-top: 8px;
  }
}

@media (max-width: 767px) {
  .commonInFilter {
    margin-top: 8px;
  }
}

/*....................Filter Bar.................*/

.commonFilterDiv {
  width: 100% !important;
  margin-top: 0px !important;
}

.commonFilterSelect {
  width: 100% !important;
  height: 35px;
}

.cmnFilterForm.form-control {
  width: 245px;
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #0000004d;
  border-radius: 0rem;
  display: block;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*.....................Count Result................*/
.commonRecrd {
  float: right !important;
}

.cmnRecordIcon {
  font-size: 15px;
}

.cmnResutText {
  opacity: 0.7;
}

.cmnPageCount {
  padding: 5px;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.7;
}

@media (max-width: 575px) {
  .commonRecrd {
    float: left !important;
  }
}

/* .................Table .........................*/

.cmnMainTable th {
  /* text-align: center; */
  background-color: #0077b6;
}

.cmnMainTableHd {
  text-transform: uppercase;
  font-size: 15px;
  color: #f5f5f5;
}

.cmnHdName {
  font-size: 24px;
  font-family: Roboto, sans-serif;
}

.cmn_Cap {
  text-transform: capitalize;
}

.cmnViewIcon {
  cursor: pointer;
  color: #233e56 !important;
}
.cmnViewIconBan {
  cursor: not-allowed;
  color: #233e56 !important;
}
/*...................Order Pages Search Styling...........*/
.userSrchList {
  max-height: 150px;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.45);
  overflow-y: scroll;
  z-index: 1;
  position: absolute;
  background-color: #dddddd;
}

.btnStylefleet {
  width: 100%;
  padding: 2%;
  text-align: left;
}

/* ................edit model page................... */

.cmnEditForm .form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #e4e7ea;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(32, 168, 216, 0.25);
}

/* ..................view model page................*/
.cmnViewDiv {
  height: 280px !important;
  overflow-x: hidden !important;
  width: 70% !important;
  margin: auto;
}

.cmnActivCanclBtn {
  padding-left: 18%;
  padding-right: 18%;
  float: right;
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivCanclBtn:hover {
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivCanclBtn:active {
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivCanclBtn:focus {
  border-color: #0077b6 !important;
  background-color: #0077b6 !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #0077b6 !important;
}

.cmnActivOkBtn {
  padding-left: 12%;
  padding-right: 12%;
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivOkBtn:hover {
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  color: white !important;
}

.cmnActivOkBtn:active {
  background-color: #f86c6b !important;
  border-color: #f86c6b !important;
  color: white !important;
}

.cmnActivOkBtn:focus {
  border-color: #f86c6b !important;
  background-color: #f86c6b !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #f86c6b !important;
}

.Commondetlsmodelview {
  overflow-x: hidden !important;
  width: 70% !important;
  margin: auto;
}

@media (max-width: 991px) and (min-width: 320px) {
  .Commondetlsmodelview {
    width: 100% !important;
  }
}
/* ....................selectbox........................... */
.commonselectbox .form-control {
  width: 245px;
  margin-left: -40px;
  color: #5c6873;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0000004d;
  border-radius: 0rem;
  display: block;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.commonselectbox .form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border: 1px solid #0000004d;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(32, 168, 216, 0.25);
}

.commonselectbox .form-group {
  margin-bottom: 0px;
}

/* ..................Delete modal box.....................*/
.cmnDelCnclBtn {
  width: 100px;
  float: right;
  color: white;
}

.cmnDelCnclBtn:hover {
  background-color: #f86c6b !important;
  border-color: #f86c6b !important;
  color: white !important;
}

.cmnDelCnclBtn:active {
  background-color: #f86c6b !important;
  border-color: #f86c6b !important;
  color: white !important;
}

.cmnDelCnclBtn:focus {
  border-color: #f86c6b !important;
  background-color: #f86c6b !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #f86c6b !important;
}

.cmnDelOkBtn {
  width: 100px;
  color: white;
}

.cmnDelOkBtn:hover {
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  background-color: #3a9d5d !important;
  border-color: #3a9d5d !important;
  color: white !important;
}

.cmnDelOkBtn:active {
  background-color: #0077b6 !important;
  border-color: #0077b6 !important;
  background-color: #3a9d5d !important;
  border-color: #3a9d5d !important;
  color: white !important;
}

.cmnDelOkBtn:focus {
  border-color: #0077b6 !important;
  background-color: #0077b6 !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #0077b6 !important;
  border-color: #3a9d5d !important;
  background-color: #3a9d5d !important;
  outline: 0;
  box-shadow: 0 0 0 0rem #3a9d5d !important;
}

/* .................Description dot style................... */

.commonDesc {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.commonDesc1 {
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*.................No data image.......................*/

.noDataTextDiv {
  text-align: center;
  opacity: 0.6;
}

.noDataImg {
  width: 60px;
  margin: auto;
  justify-content: center;
  display: flex;
  opacity: 0.6;
}

/*.....................Pagination..............................*/
.paginationDiv {
  justify-content: center;
  display: flex;
}

.paginationDiv .pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.paginationDiv .pagination > .active > a {
  background-color: #0077b6;
  border-color: #0077b6;
  color: #fff;
}

.paginationDiv .pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.paginationDiv .pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #0077b6;

  outline: none;
}

.paginationDiv .pagination > li > a,
.pagination > li > span {
  color: #0077b6;
}

.paginationDiv .pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.paginationDiv .pagination > .disabled > a {
  color: #0077b6 !important;
  cursor: default;
}

/* ................Detailed View..................*/
.storeModalCap {
  text-transform: capitalize;
  width: 300px;
}
.storeModalTbl {
  overflow-x: hidden !important;
  width: 70% !important;
  margin: auto;
}
.storeSecndModalTbl {
  width: 70% !important;
  margin: auto;
}

.storeTdWdth {
  width: 200px;
}

.storeNavdiv .nav {
  margin-left: 125px;
}

.storeModalDesc {
  text-align: justify;
}

/* ....................Image style....................... */

.storeImg {
  width: 80px;
  height: 80px;
  margin: 5px;
}

/* .........................common style..................*/
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #00000008 !important;
}

.detailModal {
  padding-top: 9.5rem;
}
.detailModal {
  padding-top: 8.5rem;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

.detailModal2 {
  padding-top: 1.5rem;
  /* background-color: rgba(0, 0, 0, 0.2); */
}

.detailModal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  /* height: 700px !important; */
}

@media (max-width: 991px) and (min-width: 320px) {
  .storeSecndModalTbl {
    width: 100% !important;
    height: unset !important;
  }
  .storeNavdiv .nav {
    margin-left: 15px;
  }
}

/*....................Common usage......................*/
.boldClass {
  font-weight: bold;
}

.storeprisptnImg {
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 41px;
  width: 45px;
  height: 42px;
}

.imageModalClass {
  width: 50px;
  height: 50px;
}
.chemistdetailspera {
  margin-bottom: 6px !important;
}
.underprocessImg {
  width: 25%;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
  padding-bottom: 10px;
  padding-top: 12%;
}

.modelboxdesigns1 .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #0077b6;
  border-radius: 0.25rem;
  width: 55em;
  margin-left: -20px;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  font-size: 30px;
  color: #f5f5f5;
  outline: none;
}

.profilebasicdetailslicenseename {
  font-weight: 500 !important;
  font-size: 16px !important;
  font-family: Roboto, sans-serif !important;

  margin: 0;
}

.profilebasicdetailscontper {
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 40px !important;
  line-height: 16px;
  text-transform: capitalize;
  margin: 0;
  font-family: Roboto, sans-serif !important;
}
.upiDetail {
  font-weight: 500 !important;
  font-size: 14px !important;
  height: 40px !important;
  line-height: 16px;
  /* text-transform:lowercase; */
  margin: 0;
  font-family: Roboto, sans-serif !important;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  display: none;
}

.scrollbar-container.sidebar-nav.ps.ps-container.ps--active-y {
  background-color: #ffffff;
}

.sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
  width: 265px;
  background-color: #ffffff;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.agent-avatar {
  margin: auto;
  width: 37px;
  position: relative;
}

.basic-prof-hd {
  font-family: Roboto, sans-serif !important;
  font-size: 16px !important;
  font-style: bold;
  margin: 0px;
  color: #3e5669;
  font-weight: 600;
}
.basic-prof-dtls {
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-size: 14.5px !important;
  text-transform: capitalize;
  line-height: 19px;
  height: 37px !important;
  color: black;
}

.profdtls-email {
  font-size: 14px !important;
  height: 37px !important;
  line-height: 19px;
  color: black;
  font-family: Roboto, sans-serif !important;
  font-style: normal;
}
.dtls-head-align {
  color: #0077b6;
  font-weight: 500;
  font-size: 19px;
}
.detail-modal-align {
  padding-top: 8.5rem !important;
  background-color: rgba(14, 13, 13, 0.2) !important;
}

.model-Head-detailview {
  padding-top: 31px;
  font-size: 35px;
  background-color: #f0f3f5 !important;
}
.detail-modal-align ::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  display: block !important;
}
.detail-modal-align ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.detail-modal-align ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: grey !important;
}
.prof-image {
  border-radius: 80px;
  width: 65px;
  height: 65px;
  margin-bottom: -67px;
  margin-left: 20px;
}
/* .img-avatar {
  width: 65px !important;
  height: 65px !important;
} */
.img-avatar-headr {
  width: 50px !important;
  height: 50px !important;
  margin-right: 20px !important;
}
.viewmodal-title span {
  text-transform: capitalize;
}
.viewmodal-title {
  margin-left: 20px;
}
.feedbkModalTbl-agnt-dtl {
  height: 276px !important;
  overflow-x: hidden !important;
  margin: auto;
}
.feedbkModalTbl-agnt-dtl table {
  margin-bottom: 0px !important;
}
.feedbkModalTbl-agnt-dtl .lft-align {
  margin-top: 15px;
}
.otr-row-align {
  margin-top: 24px;
}
.dtls-head-align {
  color: #0077b6 !important;
  font-weight: 500 !important;
  font-size: 17px !important;
}
/*....................Common usage master data modal......................*/
.mastr-mdl-hd {
  font-size: 35px;
  background-color: #0077b6;
  color: #f5f5f5;
}
.modal-header.close {
  color: #f5f5f5;
}
.mastr-mdl-ftr {
  background-color: #fff;
}

.detail-ad-cat-Modal {
  padding-top: 5.4rem !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.detail-ad-Modal {
  padding-top: 8.5rem;
  background-color: rgba(0, 0, 0, 0.2);
}
.tble-add-btn {
  margin-bottom: 15px !important;
}
.add-btn-align-icn {
  margin-left: 5px;
  font-weight: bold;
}
.tabl-slNo-field {
  width: 70px;
}
.modl-titl-align {
  font-size: 20px;
}
.is-active-lbl {
  display: flex;
  margin-top: 8px;
}
.delete-msg {
  text-align: center;
  margin-top: 20px;
}
.delete-btn-rw {
  margin-bottom: 20px;
  margin-top: 30px;
}
.search-tbl-th {
  width: 200px;
}
.form-grp-wd {
  width: 100%;
}

/* ----------------common view modal------------ */

.viewModalCap {
  text-transform: capitalize;
  width: 300px;
}
.viewModalTbl {
  overflow-x: hidden !important;
  /* width: 70% !important; */
  margin: auto;
  max-width: 550px;
}
.viewModalTdWdth {
  width: 150px;
}

/* -------------------Common Error message------------------ */
.errStyle {
  color: red;
  font-size: 12px;
}
.mandatoryField:after {
  content: " *";
  color: red;
}
.btn_cmn:active {
  background-color: #0077b6;
}
.btn_cmn {
  background-color: #0077b6;
}
.btn_cmn:hover {
  background-color: #0077b6;
}
.btn_cmn:focus {
  background-color: #0077b6;
}

.cmnRecrd {
  float: right;
  margin-top: 8px;
}
@media (max-width: 575px) {
  .cmnRecrd {
    float: left !important;
  }
}

.nav-tabs .nav-link.active {
  color: white !important;
  background: #0077b6;
  border-color: #0077b6;
  border-bottom-color: #0077b6;
  border-bottom-color: #0077b6;
  padding-left: 20px;
  padding-right: 20px;
  width: 180px;
  text-align: center;
  /* border-top-left-radius: 7rem; */
}
.nav-tabs .nav-link.active:focus {
  background: #0077b6;
  border-color: #0077b6;
  border-bottom-color: #0077b6;
  border-bottom-color: #0077b6;
  padding-left: 20px;
  padding-right: 20px;
  width: 180px;
  text-align: center;
  /* padding-left: 6%;
  padding-right: 6%; */
  /* border-top-left-radius: 7rem; */
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e4e7ea00 #e4e7ea00 #c8ced300;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  background: #bebebe;
  border-color: #bebebe;
  border-bottom-color: #bebebe;
  border-bottom-color: #bebebe;
  padding-left: 20px;
  padding-right: 20px;
  width: 180px;
  text-align: center;
  /* padding-left: 6%;
  padding-right: 6%; */
  /* border-radius: 25px; */
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  margin-left: 10px;
  color: black !important;
  margin-bottom: 5px;
  /* width: 220px; */
}

/* .............................Responsive view....................... */

@media only screen and (max-width: 600px) {
  /* .nav-tabs .nav-link {
    width: 35%;
    padding-left: 2%;
    padding-right: 0%;
  }
  .nav-tabs .nav-link.active {
    width: 35%;
    padding-left: 2%;
    padding-right: 0%;
  }
  .nav-tabs .nav-link.active:focus {
    width: 35%;
    padding-left: 2%;
    padding-right: 0%;
  } */
}

/*....................................................................*/
.salesreturndiv {
  margin-bottom: 5%;
  margin-top: -6%;
}
.salesreturndiv .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.2rem;
  box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12) !important;
  margin-bottom: 1.5rem;
}
.salesreturndiv .table tbody + tbody {
  border-top: 1px solid #c8ced3;
}

/* .nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus {
  background: #67B7D1;
  border-color: #67B7D1;
  padding-left: 6%;
  padding-right: 6%;
} */
.tab-content .tab-pane {
  padding: 1rem;
}

.nav-tabs {
  border-bottom: 0px solid #0077b6;
  margin-left: 10px;
  margin-top: 15px;
}
.tab-content {
  margin-top: 25px;
  background: #fff;
  border: 0px solid #c8ced3;
  margin-bottom: 25px;
}

/* -------------------------Common View Page--------- */
.cmnViewHead {
  font-weight: 500;
  font-size: 14px;
  color: #3e5669;
  margin: 0;
}

.cmnViewData {
  font-weight: 500;
  font-size: 14px;
  /* height: 60px;
  line-height: 16px; */
  margin: 0;
  text-transform: capitalize;
  text-align: justify;
}

.cmnViewDiv {
  margin-bottom: 20px;
  margin-top: 20px;
}
.cmnViewRow {
  margin-bottom: 10px;
}

/* .........................Text Capital............... */

.cmnTextCaptital {
  text-transform: capitalize;
}
.cmnTextDesc {
  text-transform: capitalize;
  text-align: justify;
}
/*........................Common filter icon...............*/
.cmnfilter {
  float: right;
  /* margin-top: 10px; */
  color: #0077b6;
  font-size: 25px;
  cursor: pointer;
}

/*total earnings box*/
/* .container {
  margin-top: 100px
} */

.counter-box {
  border-radius: 5px;

  display: block;
  background: #0077b6;
  /* padding: 40px 20px 37px; */
  text-align: center;
  /* height:80px; */
  width: 150px;
  border: 2px solid #0077b6;
}
.totlAmntStyle {
  /* padding: 5px; */
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.counter-box p {
  margin: 5px 0 0;
  padding: 0;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}

.counter {
  display: block;
  font-size: 22px;
  font-weight: 700;
  color: #666;
  line-height: 10px;
}

.counter-box.colored {
  background: #0077b6;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: #fff;
}

/* scrolll */

/* .scrolltable
{
  margin-top: 15px;
  overflow: auto;
  height: 230px;
  scroll-behavior: smooth;
  scrollbar-width: auto !important;
  margin-bottom: 30px;
  -webkit-appearance: none;
  padding-left: 60px;
  padding-right: 60px;
} */
.astrk {
  color: red;
}
.label-inpt {
  font-size: 13.5px;
}

.resetimg {
  width: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* facebook connected div */
.cmnFbDiv {
  width: 180px;
  /* padding: 50px; */
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  /* height: calc(1.5em + 0.75rem + 2px); */
  /* padding: 0.375rem 0.75rem; */
  /* font-size: 0.875rem; */
  font-weight: 400;
  /* line-height: 1.5; */
  /* color: black; */
  background-color: white;
  text-align: center;
  padding: 10px;
  padding: 5px;
}
.facebookCnct {
  font-size: 25px;
  color: #0077b6;
  margin-right: 10px;
  /* padding-left: 10px;
  padding-right: 10px; */
}
.connectLbl {
  font-size: 16px;
  font-weight: bold;
}
.custCreditLbl {
  font-size: 16px;
  font-weight: bold;
  color: #0077b6;
  cursor: pointer;
}
.fbConctTick {
  font-size: 25px;
  color: green;
  margin-left: 10px;
}

/* Detail view heading style */
.cmnDetailHead {
  float: left;
  font-size: 18px;
  font-weight: 700;
  color: #0077b6;
  margin-top: 20px;
}

/* QRcode style */
.viewqrimage {
  height: 125px;
  width: 125px;
  /* padding: 10px; */
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  margin-top: 15px;
  margin: auto;
}
.starIconstyle {
  color: goldenrod;
}
.pageCloseIcon {
  margin-top: 0px;
  margin-right: 25px;
  color: #0077b6;
}
.pageRightCloseIcon {
  margin-left: 119px;
  color: #0077b6;
  margin-top: 0px;
}
.starIconstyle-ledger {
  color: goldenrod;
  font-size: 20px;
}

.ledger-amount-main {
  display: flex;
  gap: 10px 20px;
}

.totlAmntStyle-ledger {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.counter-box-ledger {
  border-radius: 5px;
  margin-bottom: 14px;
  display: block;
  background: #0077b6;

  text-align: center;

  width: 186px;
  border: 2px solid #0077b6;
}

.counter-box-ledger.colored {
  background: #0077b6;
}

.counter-box-ledger p {
  margin: 5px 0 0;
  padding: 0;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}

.counter-box-ledger i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}

/* image close icon product */
.imgCloseIcon {
  position: absolute;
  right: 100px;
  top: 73px;
  width: 40px;
  height: 32px;
  opacity: 1;
}
.imgCloseIcon:hover {
  opacity: 1;
}

/* check icon style for lead order */
.CheckIconStyle {
  color: green;
  font-size: 25px;
}

.cleardate {
  position: relative;
  top: 10px;
  right: 10px;
  color: #216ba5 !important;
}
.form-control[readonly] {
  background-color: #fff;
}

.total-amnt {
  margin-left: 5px;
  /* background-color: #0077b6; */
  color: black;
  padding: 4px 7px;

  margin-right: 7px;

  font-weight: 600;
  font-size: 18px;
  opacity: 0.7;
}
.total-amnt::before {
  content: ":";
  margin-right: 4px;
}
/* .totaltitle {
  margin-top: 10px;
} */
.total-label {
  text-transform: capitalize;
  font-size: 14px;
}
.reportamnttitle {
  display: flex;
}
/* .total-label::after {
 
} */

/*distributor order export*/

.disOderDwnld {
  margin-bottom: 15px;
  margin-left: 15px;
  float: right;
}
.disOderExprt1 {
  margin-bottom: 15px;
  float: right;
}
@media (max-width: 767px) and (min-width: 320px) {
  .disOderDwnld {
    margin-bottom: 15px;
    margin-left: 0px;
    margin-right: 10px;
    float: left;
  }
  .disOderExprt1 {
    margin-bottom: 15px;
    float: left;
  }
}
