.order_rowClass {
  padding-bottom: 20px;
  border-bottom: 2px solid #c8ced3;
}
.orderAddTbl td {
  vertical-align: middle;
}
.orderAddTbl th {
  color: #3e5669;
}
.orderSelectDiv {
  margin: auto;
  max-width: 250px;
}
.imageModalClass1 {
  width: 50px;
  height: 50px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Order payment */
.divLabel {
  margin-bottom: 10px;
}
.payLabel {
  font-family: Roboto, sans-serif !important;
  font-size: 12px !important;
  color: #3e5669;
  font-weight: 600;
}
.payCrncy {
  font-family: Roboto, sans-serif !important;
  font-size: 16px !important;
  color: white;
  font-weight: 600;
}
.payAmount {
  font-family: Roboto, sans-serif !important;
  font-size: 20px !important;
  color: white;
  font-weight: 600;
}
.amntDiv {
  border: 1px solid green;
  width: 150px;
  background-color: green;
  border-radius: 50px 20px;
  padding: 5px;
  text-align: center;
  /* margin: auto; */
}
.amntDivPnding {
  border: 1px solid #2c77b7;
  width: 150px;
  background-color: #2c77b7;
  border-radius: 50px 20px;
  padding: 3px 0px;
  text-align: center;
  margin: auto;
}
.payOrdr {
  /* text-align: center; */
  margin: auto;
  padding: 10px 0px;
  color: #3e5669;
}
.paidAmnt {
  /* text-align: center; */
  margin: auto;
  padding: 10px 0px;
  color: #3e5669;
}
.totalCrncy {
  font-family: Roboto, sans-serif !important;
  font-size: 12px !important;
  color: #3e5669;
  font-weight: 600;
}
.totAmount {
  font-family: Roboto, sans-serif !important;
  font-size: 16px !important;
  color: #3e5669;
  font-weight: 600;
}
.paidAmount {
  font-family: Roboto, sans-serif !important;
  font-size: 16px !important;
  color: #3e5669;
  font-weight: 600;
}
.totlAmntStyle-order {

  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

.counter-box-order {
  border-radius: 5px;
 margin-bottom: 14px ;
  display: block;
  background: #0077b6;

  text-align: center;

  width: 150px;
  border: 2px solid #0077b6;
}

.counter-box-order.colored {
  background: #0077b6;
}


.counter-box-order p {
  margin: 5px 0 0;
  padding: 0;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.counter-box-order i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}