.cardMain {
  width: 27rem;
  float: right;
  margin-top: 180px;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);

  margin-bottom: 30px;
  height: 72%;
}
.loginpagemain {
  margin: auto;
  justify-content: center;
  display: flex;
}
.loginHead {
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 15px;
  color: rgb(182, 16, 16);
  margin-left: 2px;
}
.loginHd {
  font-weight: 600;
  text-align: center;
  color: black;
}
.cardhead {
  font-weight: 500 !important;
  margin-top: 0px;
  text-align: center;
  font-size: xx-large;
  background-color: #21b81c;
}
.cardbodylog {
  flex: 1 1 auto;
  padding: 0rem !important;
}

.loginfrstImg {
  width: 33px;
  border-radius: 50%;

  height: 40px;
  margin-top: 15px;
}
.bgcolor_login {
  min-height: 768px;
  background-position: "center";
  background-size: cover;
  background-repeat: "no-repeat";
}
.main_colum {
  justify-content: center;
  align-items: center;
  display: flex;
}
.SignInHead {
  text-align: center;
  padding-top: 15px;
}
.CreateAccountText {
  text-align: center;
  color: #2a6576;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.forgotPswd {
  color: #023e8a;

  font-size: 16px;
  text-align: center !important;
}
.forgotPswd:hover {
  color: #023e8a;
  text-decoration: none;
}
.loghead {
  text-align: center;
  margin-top: 32px;
  margin-bottom: 42px;
}

.login_form .form-control {
  margin-top: -13px;
  display: block;
  width: 52%;
  margin-left: 20%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  background-color: rgb(202, 5, 5) !important;
  background-clip: padding-box;
  box-shadow: 0 0 0 0.2rem white;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
}
.errormessage {
  margin-bottom: -8px;

  margin-top: 5px;
  color: red;
  font-size: 14px;
}
.forgotPswddiv {
  margin-top: 10px;
  text-align: center !important;
  margin-bottom: 10%;
}
.login_form .form-group {
  margin-bottom: 0.5px;
}
.loginbtn {
  margin-bottom: 18px;

  justify-content: center;

  display: flex;
  margin-top: 20px;

  width: 58%;
  margin-left: 82px;
  color: #fff;
  background-color: rgb(23, 62, 83);
  border-color: rgb(23, 62, 83);
}
.remember {
  font-size: 0.875rem;
  margin-left: 116px;
  color: #2a6576;
  margin-top: -35px;
}

.nameclr {
  color: black;
  margin-top: 84px;
  text-align: center;
}
.backgroundclr {
  min-height: 768px;

  background-position: "center";
  background-size: cover;
  background-repeat: "no-repeat";
}
.sub {
  align-items: center;
}

.frgtpara {
  text-align: center;
  padding-top: 15px;
}
.frgtpara1 {
  line-height: 30px;
}
.frgthead {
  text-align: center;
}
/* input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  margin-left: 19%;
} */

/* ===============================newlogin============================================ */

.cardMainlogin {
  width: 50rem;
  height: 28rem;
  margin: auto;
  background-color: white;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 22px;
  margin-top: 15% !important;
}
.loginheader {
  font-size: 24px;
  color: #023e8a;
  text-align: center !important;
  margin-top: 25%;
  font-weight: 600 !important;
  text-align: center !important;
}
.loginimage {
  width: 100%;
  margin-top: 42%;
  margin-bottom: 42%;
  margin-left: 18px;
}
.loginformlabel {
  font-size: 13px;
  color: #000000b3;
  font-weight: 300 !important;
}
.loginform {
  margin-top: 3%;
}
.loginundelne {
  border-bottom: 2px solid #023e8a;
  margin-top: 5%;
}
.contactpagebtn {
  width: 100%;
  margin-top: 15px;
  padding-top: 7px;
  padding-right: 10px;
  padding-bottom: 7px;
  padding-left: 10px;

  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: white;

  background-color: #023e8a;
  box-shadow: 1px solid #023e8a;
  border: 1px solid #023e8a !important;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
}
.contactpagebtn:hover {
  background-color: #0077b6 !important;
  box-shadow: 1px solid #0077b6 !important;
  border: 1px solid #0077b6 !important;
  color: white;
  border: 1px solid #0077b6;
}
.contactpagebtn:active {
  background-color: #302b3f !important;
  box-shadow: 1px solid #302b3f !important;
  border: 1px solid #302b3f !important;
}
.loginform .form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #e4e7ea !important;
  outline: 0;
  box-shadow: 0 0 0 0 #e4e7ea !important;
}
.contactpagebtn:focus {
  border-color: #023e8a !important;
  background-color: #023e8a !important;

  outline: 0;
  box-shadow: 0 0 0 0 #023e8a !important;
}
.loginform .form-control {
  display: block;
  width: 100%;
  height: calc(1.7em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #735c67;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 2px solid #f5f5f5;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: -11px;
}
.loginform .form-group {
  margin-bottom: 1px;
}
.loginformnames {
  font-size: 12px;
}
.loginforgot {
  font-size: 14px;
  color: #5c6873;
  margin-top: 8%;
  text-align: center;
  font-weight: 300 !important;
  cursor: pointer;
  margin-bottom: 10%;
}
@media (max-width: 991px) and (min-width: 768px) {
  .cardMainlogin {
    width: 47rem;
    margin-top: 128px !important;
    margin-left: -4% !important;
    margin-right: 0%;
  }
  .loginheaderfrst {
    margin-left: -41px;
  }
}
@media (max-width: 767px) and (min-width: 520px) {
  .cardMainlogin {
    width: 30rem;
    margin-top: 128px !important;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 10%;
  }
  .loginimage {
    /* width: 81%;
    margin-top: 0%;
    margin-bottom: 0%;
    margin-left: 20px; */
    display: none;
    padding-top: 10px;
    padding-bottom: 50px;
  }
  .loginheaderfrst {
    margin-left: 10%;
    margin-right: 10%;
  }
  .loginheader {
    font-size: 24px;
    color: #0077b6;
    margin-top: 10%;
    font-weight: 600 !important;
  }
  .forgotPswddiv {
    margin-top: 15px;
    text-align: center !important;
    margin-bottom: 10%;
  }
  .logintitle {
    text-align: center;
  }
}

@media (max-width: 519px) and (min-width: 480px) {
  .cardMainlogin {
    width: 27rem;
    margin-top: 130px !important;
    margin-left: 2%;
    margin-right: 0%;
    margin-bottom: 10%;
  }
  .loginimage {
    display: none;
  }
  .loginheaderfrst {
    margin-left: 10%;
    margin-right: 10%;
  }
  .loginheader {
    font-size: 24px;
    color: #0077b6;
    margin-top: 10%;
    font-weight: 600 !important;
  }
  .forgotPswddiv {
    margin-top: 15px;
    text-align: center !important;
    margin-bottom: 10%;
  }
  .logintitle {
    text-align: center;
  }
}
@media (max-width: 479px) and (min-width: 430px) {
  .cardMainlogin {
    width: 23rem;
    margin-top: 128px !important;
    margin-left: 5%;
    margin-right: 0%;
    margin-bottom: 10%;
  }
  .loginimage {
    display: none;
  }
  .loginheaderfrst {
    margin-left: 10%;
    margin-right: 10%;
  }
  .loginheader {
    font-size: 24px;
    color: #0077b6;
    margin-top: 15%;
    font-weight: 600 !important;
  }
  .forgotPswddiv {
    margin-top: 15px;
    text-align: center !important;
    margin-bottom: 10%;
  }
  .logintitle {
    text-align: center;
  }
}
@media (max-width: 429px) and (min-width: 330px) {
  .cardMainlogin {
    width: 18rem;
    margin-top: 138px !important;
    margin-bottom: 10%;
  }
  .loginimage {
    display: none;
  }
  .loginheaderfrst {
    margin-top: 10%;
    margin-left: 10%;
    margin-right: 10%;
  }
  .loginheader {
    font-size: 24px;
    color: #0077b6;
    margin-top: 10%;
    font-weight: 600 !important;
  }
  .forgotPswddiv {
    margin-top: 15px;
    text-align: center !important;
    margin-bottom: 10%;
  }
  .signupanaccount {
    font-size: 12px;
    text-align: center;
    justify-content: center;
    display: flex;
    margin-top: 15px;
    color: black;
  }
  .accountsignup {
    font-size: 14px;
    color: #302b3f !important;
    margin-left: 5px;
  }
  .logintitle {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  /* toast message responsive */

  .Toastify__toast {
    margin-left: 40%;
  }
}

.signup-div {
  margin-top: 3px;
}

.pswd-container-lgn {
  position: relative;
}

.pswd-lgn {
  position: absolute;
  top: 18px;
  right: 10px;
  cursor: pointer;
}

input[type="password"] {
  padding-right: 30px;
}
