
.Button {
    /* width: 100px; */
    color: white;
    font-weight: 700;
    background-color: #0077b6 !important;
    border-color: #0077b6 !important;
    float: center;
    padding: 2 rem;
    
  }
  .resetimg {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding-top: 5px;
  }
  .temporarily
  {
  font-weight: bold;  }

/* h4
{
  font-weight: bolder; 
  color: rgb(1, 2, 2);
} */
.salesreturndiv {
  margin-bottom: 5%;
  margin-top: -6%;
  
}
.detail-ad-Modal {
  padding-top: 5.5rem;}
.Modalbox
{
  padding-top: 3rem;
  background-color: #e9e9e9;

}
.SuspendImg
{
  padding-bottom: 20px;
}
.Modal-expired{
  /* border-radius: 22px; */
  background-color: #023e8a !important;

}