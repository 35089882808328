
  .updateprofilediv {
    margin-top: 8%;
  }
  .updateprofilecard {
    margin-bottom: 0rem;
    width: 70%;
    justify-content: center;
    display: flex;
    margin: auto;
    /* height: 690px; */
  }  
  .stepperdesign {
    margin-top: 8px;
    font-size: 14px !important;
  
    font-weight: 500;
  
    text-align: center;
  
    display: block;
  
    color: rgb(0, 0, 0);
  }
  .step-cont {
    width: 100%;
  }
  .next-btn {
    height: 44px;
    width: 122px;
    float: right;
    background-color: #023E8A;
    box-shadow: 1px solid #023E8A;
    border: 1px solid #023E8A !important;
    color: white;
    margin-top: 72px;
    font-size: 19px;
  }
  .prev-btn {
    float: left;
    background-color: #023E8A;
    box-shadow: 1px solid #023E8A;
    border: 1px solid #023E8A !important;
    color: white;
    /* margin-top: 72px; */
    font-size: 19px;
    height: 44px;
    width: 122px;
  }
  .step-head a {
    font-size: 18px;
    font-weight: 500 !important;
  }
  /* .signup-page-div{
    margin-top: 98px;
  } */
  .back-to-login{
    margin-top: 72px;
    font-size: 19px;
   
  }
  .main-container{
    margin-top: 0px;
  }
  /* .buttons-step-div{
padding-top: 12px;
  } */
  .signup-image {
    width: 100%;
    /* margin-top: 42%; */
    /* margin-bottom: 42%; */
    margin-left: 18px}
.businessType-label{
  font-size: 18px;
}
.businessType-radio-label{
  font-size: 20px;

}
.divBorderAlign{
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0.5rem;
    border: 1px solid rgb(168, 165, 165);
  width: fit-content;
  border-radius: 10px;
}

    @media (max-width: 429px) and (min-width: 330px) {
      
      .signup-image {
        display: none;
      }}

      @media (max-width: 767px) and (min-width: 520px) {
     
        .signup-image {
          
          display: none;
          padding-top: 10px;
          padding-bottom: 50px;
        }}

        @media (max-width: 1366px) and (min-width: 1024) {
     
          .signup-image {
            
        
    margin-top: 110%;
    /* margin-bottom: 42%; */
    margin-left: 18px;
          }}


          @media (max-width: 640px) and (min-width: 360px) {
     
            .prev-btn  {
              
              float: left;
          
              background-color: #023E8A;
    box-shadow: 1px solid #023E8A;
    border: 1px solid #023E8A !important;
    color: white;
           
              font-size: 19px;
              height: 36px !important;
              width: 69px !important;
            }
            .next-btn {
              height: 36px;
              width: 68px;
              float: right;
              background-color: #023E8A;
              box-shadow: 1px solid #023E8A;
              border: 1px solid #023E8A !important;
              color: white;
              color: white;
              margin-top: -36px;
              font-size: 19px;
            }
          }
          @media (max-width: 568px) and (min-width: 320px) {
     
            .prev-btn  {
              
              float: left;
          
              background-color: #023E8A;
              box-shadow: 1px solid #023E8A;
              border: 1px solid #023E8A !important;
              color: white;
           
              font-size: 19px;
              height: 36px !important;
              width: 69px !important;
            }
            .next-btn {
              height: 36px;
              width: 68px;
              float: right;
              background-color: #023E8A;
              box-shadow: 1px solid #023E8A;
              border: 1px solid #023E8A !important;
              color: white;
              margin-top: -36px;
              font-size: 19px;
            }
          }
          @media (max-width: 1024px) and (min-width: 768px) {
      
            .signup-image {
              display: none;
              
            }
            .next-btn {
              height: 44px;
              width: 122px;
              float: right;
              background-color: #023E8A;
              box-shadow: 1px solid #023E8A;
              border: 1px solid #023E8A !important;
              color: white;
              margin-top: -45px;
              font-size: 19px;
            }}
            @media (max-width: 1366px) and (min-width: 1024px) {
      
              .next-btn {
                height: 44px;
                width: 122px;
                float: right;
                background-color: #023E8A;
                box-shadow: 1px solid #023E8A;
                border: 1px solid #023E8A !important;
                color: white;
                margin-top: 72px;
                font-size: 19px;
              }}
              @media (max-width: 653px) and (min-width: 280px) {
      
                .next-btn {
                  height: 29px;
                  width: 67px;
                  float: right;
                  background-color: #023E8A;
                  box-shadow: 1px solid #023E8A;
                  border: 1px solid #023E8A !important;
                  color: white;
                  margin-top: -29px;
                  font-size: 13px;
                  margin-right: -16px;
                }
              
                .prev-btn  {
              
                  float: left;
                  background-color: #023E8A;
                  box-shadow: 1px solid #023E8A;
                  border: 1px solid #023E8A !important;
                  color: white;
               
                  font-size: 13px;
                  height: 29px;
                  width: 67px;
                  margin-left: -10px;
                }}
              
                  @media (max-width: 568px) and (min-width: 320px) {
     
                    .back-to-login {
                      margin-top: 35px;
                      font-size: 22px;
                  }
                    }
                    @media (max-width: 667px) and (min-width: 375px) {
     
                      .back-to-login {
                        margin-top: 35px;
                        font-size: 19px;
                    }
                      }
                      @media (max-width: 1024px) and (min-width: 768px) {
                    .back-to-login {
                            margin-top: 13px;
                            font-size: 25px;
                        }}
                        @media (max-width:1366px ) and (min-width: 1024px) {
                          .back-to-login {
                          margin-top: 61px;
                      font-size: 25px;
                                             }}
                   @media (max-width:720px ) and (min-width: 540px) {
                        .back-to-login {
                               margin-top: 35px;
                                 font-size: 22px;
                                                                 }}
                                                                 
                  @media (max-width: 640px) and (min-width: 360px) {
     
                     .back-to-login {
                                margin-top: 20px;
                                font-size: 19px;
                                                                }
                                                                  }
                                                                  .radio-btn-align {
                                                                    font-size: 13.5px;
                                                                  }
                                                                  .radio-inpt {
                                                                    width: 21px;
                                                                  }
                                                                  .asterik{
                                                                    color: red;
                                                                  }