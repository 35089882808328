/* .navbar {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  
  padding-top: 20px;
  padding-bottom: 0px;
  z-index: 1;
} */
/* .navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: -12px;
  list-style: none;
} */
.logoNav .active {
  border-bottom: none !important;
  height: 75px;
}
.mainpagenavbar {
  color: white;
  /* padding-left: 30px; */
  margin-left: 29px;
  padding-top: 20px;
  /* margin-bottom: -17px; */
  text-decoration: inherit !important;
  /* letter-spacing: 0.01em !important; */

  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.35em;
  text-align: center;
  letter-spacing: 0.01em;
  /* color: white; */
}
/* .navbar .active {
    border-bottom: 3px solid rgb(69, 177, 55) !important;
    height: 70px;
  } */

.mainpagenavbar:hover {
  color: black;
}

.mainpagenavbar .active {
  color: black !important;

  /* border-bottom: 3px solid rgb(69, 177, 55) !important; */
}
/* .mainpagenavbar .active::before {
    background-color: rgb(69, 177, 55);
  }
  
  .mainpagenavbar :focus {
    border-bottom: 3px solid rgb(69, 177, 55) !important;
  } */

.mainpagenavbar :hover::before,
.mainpagenavbar :focus::before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.homenavbarfirst .navbar_navigation {
  color: white;
  padding-right: 132px;
  font-weight: bold;
  font-size: 25px;
  padding-left: 10px;
}
.navbtn {
  width: 90px !important;
  display: "flex";

  max-width: 280px;
  margin-top: 15px;
  text-align: center;
  color: black;
  background-color: white;
  border: 1px solid white !important;
  border-radius: 4px;
  margin-left: 15px;
}
.navbtn:hover {
  background-color: white !important;
  border-color: white !important;
  color: #000 !important;
  /* border-bottom: 3px solid red !important; */
}
.navbtn:active {
  background-color: white !important;
  border-color: white !important;
  color: #000 !important;
  /* border-bottom: 3px solid red !important; */
}
.navbtn:focus {
  /* border-color: rgb(69,177,55)!important; */
  /* background-color: rgb(69,177,55) !important; */

  outline: 0;
  box-shadow: 0 0 0 0rem rgb(69, 177, 55) !important;
}

.homenavbarfirst .navbar-light .navbar-toggler {
  color: white;
  border-color: white;
}
.homeimage {
  /* margin-left: 82px; */
  width: 180px;
  height: 60px;
  padding-bottom: 3px;
  padding-top: 3px;
}
@media (max-width: 415px) and (min-width: 300px) {
  /* .homeimage {
      margin-left: -16px;
    } */
}
@media (max-width: 991px) and (min-width: 700px) {
  .navbtn {
    margin-left: 46%;
    margin-top: 20px;
  }
}
@media (max-width: 669px) and (min-width: 200px) {
  .navbtn {
    margin-left: 42%;
    margin-top: 20px;
  }
}
.homepagenavbar {
  color: white;
  text-decoration: blink;
  padding-left: 15px;
  /* margin-top: -8px !important; */
  font-family: "Roboto", sans-serif;
  line-height: 1.35em;
  text-align: center;
  letter-spacing: 0.01em;
  font-weight: 300;
}
.homenavbar {
  margin-top: 10px !important;
}
.homepagenavbar:hover {
  color: #b3b3b3;
  text-decoration: blink;
  /* border-bottom: 3px solid red; */
}

.homepagenavbar:active {
  color: #b3b3b3;
  text-decoration: blink;
}

@media (max-width: 991px) and (min-width: 300px) {
  .homepagenavbar {
    padding-left: 48%;
  }
  /* .homeimage { */
  /* margin-left: 82px; */
  /* width: 152px;
  } */
}
@media (max-width: 699px) and (min-width: 670px) {
  .navbtn {
    margin-left: 45%;
    margin-top: 10px;
  }
}
@media (max-width: 500px) and (min-width: 450px) {
  .homeimage {
    /* margin-left: 82px; */
    width: 160px;
  }
}
@media (max-width: 450px) and (min-width: 300px) {
  /* .homeimage { */
  /* margin-left: 82px; */
  /* width: 150px;
  } */
}
@media (max-width: 700px) and (min-width: 501px) {
  /* .homeimage { */
  /* margin-left: 82px; */
  /* width: 150px;
  } */
}

@media (max-width: 450px) and (min-width: 420px) {
  .homenavbarfirst .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-left: 85%;
    margin-top: -14%;
  }
}

@media (max-width: 419px) and (min-width: 320px) {
  .homenavbarfirst .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin-left: 85%;
    margin-top: -19%;
  }
}
@media (max-width: 1021px) and (min-width: 992px) {
  .mainpagenavbar {
    color: white;
    margin-left: 15px;
  }
}
@media (max-width: 1199px) and (min-width: 1021px) {
  .mainpagenavbar {
    color: white;
    margin-left: 15px;
  }
}
.homenavbarfirst {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 1;
}
.homenavbarfirst .navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 4px; */
  background-color: #eceff1;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
}

.homenavbarfirst .navbar__link {
  margin: 1em;
  font-size: 2em;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.3em;
  text-indent: 0.3em;
  color: #181818;
  border-bottom: 3px solid transparent;
}

.homenavbarfirst .navbar__link:hover {
  transform: translateY(-2px);
}

.homenavbarfirst .navbar__link--active {
  /* border-bottom: 3px solid rgb(69, 177, 55); */
  /* transition: border-bottom 0.5s ease-in-out; */
  /* height: 73px; */
}
.logoNav {
  font-size: 30px;
  color: white;
  font-weight: 700;
  /* margin-bottom: 20px;
  margin-top: 20px; */
}
